import {createClient} from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'

const sanityConfig = createClient(window.settings.sanity)
const sanityImageUrlBuilder = imageUrlBuilder(sanityConfig)

function imageUrlFor(sanityImageData, sanityImageProperties) {
  try {
    const json = JSON.parse(sanityImageData)
    let sanityImageBuilder = sanityImageUrlBuilder.image(json)
    for (const [key, value] of Object.entries(sanityImageProperties)) {
      switch (key) {
        case 'width':
          sanityImageBuilder = sanityImageBuilder.width(value)
          break
        case 'height':
          sanityImageBuilder = sanityImageBuilder.height(value)
          break
      }
    }

    const url = sanityImageBuilder.auto('format').url()
    if (url) {
      return url.replace('https://cdn.sanity.io/images/' + settings.sanity.projectId, settings.CDN)
    } else {
      return null
    }
  } catch (e) {
    console.error('Failed to build url of sanity data', sanityImageData, e)
    return ''
  }
}

$(function () {
  $('[data-sanity-image]').each(function () {
    const $this = $(this)
    const sanityImageData = $this.attr('data-sanity-image')

    const triggerMasonry = function () {
      if (window.msnry) {
        window.msnry.masonry('layout')
      }
    }

    let sanityImageTarget
    if (this.tagName.toLowerCase() === 'img') {
      sanityImageTarget = 'src'
    } else {
      sanityImageTarget = 'background'
    }

    const sanityImageProperties = {}
    if ($this.attr('data-sanity-image-width')) {
      const width = parseInt($this.attr('data-sanity-image-width'))
      if (!isNaN(width)) {
        sanityImageProperties.width = width
      }
    }
    if ($this.attr('data-sanity-image-height')) {
      const height = parseInt($this.attr('data-sanity-image-height'))
      if (!isNaN(height)) {
        sanityImageProperties.height = height
      }
    }

    if (sanityImageData && sanityImageTarget) {
      var sanityImageUrl = imageUrlFor(sanityImageData, sanityImageProperties)
      if (sanityImageUrl) {
        preloadImage(sanityImageUrl, triggerMasonry, triggerMasonry)
        switch (sanityImageTarget) {
          case 'background':
            this.style.backgroundImage = `url('${sanityImageUrl}')`
            break
          case 'src':
            this.src = sanityImageUrl
            break
        }
      }
    }
  })
})

function preloadImage(src, loadListener, errorListener) {
  var img = new Image()
  img.addEventListener('load', loadListener)
  img.addEventListener('error', errorListener)
  img.src = src
}
